<template>
  <v-container
    fluid
    class="pa-0"
  >
    <div class="d-none d-md-block">
      <hero-component
        align="center"
        btn-location="below"
        btn-link="/travel/infousa"
        btn-text="Get started on your travel policy"
        background-url="/img/Travel-US-Background-Desktop.jpg"
      >
        We’ve got your <br /> adventurous<br /> side, covered.
      </hero-component>
      <div class="section2 pb-16">
        <div class="s2-title">
          <v-row class="d-flex justify-center">
            <v-col
              cols="12"
              class="d-flex justify-center"
            >
              <div class="section2-header">
                Protect your travel adventures with Blanket
              </div>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col class="d-flex justify-center">
              <div class="s2-sub">
                Get peace of mind for your best laid plans.
              </div>
            </v-col>
          </v-row>
        </div>
        <v-row class="d-flex justify-center mt-16 mb-n6">
          <v-col
            cols="10"
            class="d-inline-flex"
          >
            <v-col
              class="d-flex justify-center"
            >
              <v-icon
                class="primary--text s2-icon"
              >
                mdi-calendar-check
              </v-icon>
            </v-col>
            <v-col
              class="d-flex justify-center"
            >
              <v-icon
                class="primary--text s2-icon"
              >
                mdi-shield-star
              </v-icon>
            </v-col>
            <v-col
              class="d-flex justify-center"
            >
              <v-icon
                class="primary--text s2-icon"
              >
                mdi-hospital-building
              </v-icon>
            </v-col>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center mt-0 py-0">
          <v-col
            cols="10"
            class="d-inline-flex align-center"
          >
            <v-col class="d-flex justify-center">
              <div class="s2-subhead">
                Plan your next<br> trip with confidence
              </div>
            </v-col>
            <v-col class="d-flex justify-center">
              <div class="s2-subhead">
                10-Day Free Look <br> Period
              </div>
            </v-col>
            <v-col class="d-flex justify-center">
              <div class="s2-subhead">
                Covid-19 Illness <br> Coverage
              </div>
            </v-col>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center mt-0">
          <v-col
            cols="10"
            class="d-inline-flex"
          >
            <v-col class="d-flex flex-column justify-center s2-desc">
              We’ll protect against unforeseen events that could cancel or interrupt your vacation resulting in the loss of any pre-paid, non-refundable trip costs.
            </v-col>
            <v-col class="d-flex justify-center s2-desc">
              Not sure if a travel insurance plan is right for you, but want to take advantage of time-sensitive benefits? Put your plan in place today and take advantage of our 10-Day Free Look Period refund policy.
            </v-col>
            <v-col class="d-flex justify-center s2-desc">
              Before you go and during your trip, travel insurance from Blanket provides coverage for COVID-19 sickness — complete with expert 24/7 worldwide assistance while you’re away, so you can wander more and worry less.
            </v-col>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            class="d-flex justify-center"
            cols="12"
          >
            <v-btn
              class="mint text-h4 font-weight-bold darkgrey--text"
              large
              to="/travel/infousa"
            >
              Apply Now
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <div class="section4 pt-16">
        <v-row class="d-flex justify-start">
          <v-col>
            <v-row>
              <v-col
                cols="12"
                lg="10"
                xl="8"
                class="pl-0"
              >
                <div class="sec4-title">
                  Your (mis)adventures, covered.
                </div>
              </v-col>
            </v-row>
            <v-row class="d-flex justify-start">
              <v-col
                cols="12"
                lg="12"
                xl="8"
                class="pt-10"
              >
                <div class="sec4-sub">
                  When an unforeseen event happens and it interferes with your travel itinerary, our travel insurance plan covers you. Here's how:
                </div>
              </v-col>
            </v-row>

            <v-row class="d-flex justify-center">
              <v-col
                cols="12"
                class="d-flex justify-start align-start covg-txt"
              >
                <v-icon
                  left
                  large
                  class="primary--text"
                >
                  mdi-check-bold
                </v-icon>
                Our plans provide for trip cancellation reimbursement for the unused, non-refundable, prepaid trip costs if you are forced to cancel your trip for any of the numerous covered reasons.
              </v-col>
              <v-col
                cols="12"
                class="d-flex justify-start align-start covg-txt"
              >
                <v-icon
                  left
                  large
                  class="primary--text"
                >
                  mdi-check-bold
                </v-icon>
                Our plans include trip interruption and missed connection reimbursement for the unused portion of any non-refundable, pre-paid trip expense when situations like a medical emergency or a weather-related event results in a canceled flight or missed connection.
              </v-col>
              <v-col
                cols="12"
                class="d-flex justify-start align-start covg-txt"
              >
                <v-icon
                  left
                  large
                  class="primary--text"
                >
                  mdi-check-bold
                </v-icon>
                Medical emergency expenses can be covered if you’re injured or become sick while traveling and have to seek treatment. If the situation requires medical evacuation, that can also be covered, up to coverage limits.
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
      <div class="sec5-dsk">
        <v-row>
          <v-col class="sec5-title">
            We’ve got easy covered.
          </v-col>
        </v-row>
        <v-row>
          <v-col class="sec5-sub">
            Get Travel coverage in less time than it takes to clean your sunglasses.
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              large
              class="mint apply-btn ml-4"
              to="/travel/infousa"
            >
              <span class="text-h4 font-weight-bold darkgrey--text">Get your travel policy today</span>
            </v-btn>
          </v-col>
        </v-row>
        <v-img
          src="/img/renters-2022/Mobile-app-mockup.png"
          :class="xlDevice ? 'sec5-xl-phone' : 'sec5-dsk-phone'"
        ></v-img>
      </div>
      <div class="faq-sec-desk pt-16 pb-16">
        <!---------------------------------BUYING TRAVEL INSURANCE FAQs------------------------------------>
        <v-row>
          <div
            class="cov-faq-title"
            @click="toggleCovFaq"
          >
            <v-col
              cols="12"
              class="faq-header-desk"
            >
              Buying Travel Insurance FAQs
              <v-icon
                v-if="!covFaq"
                right
                x-large
                color="#005F6C"
              >
                mdi-chevron-right
              </v-icon>
              <v-icon
                v-if="covFaq"
                right
                x-large
                color="#005F6C"
              >
                mdi-chevron-down
              </v-icon>
            </v-col>
          </div>
        </v-row>
        <v-row v-if="covFaq">
          <v-col>
            <v-expansion-panels
              focusable
              accordion
            >
              <v-expansion-panel>
                <v-expansion-panel-header class=" text-h4 font-weight-bold">
                  What is travel insurance and what does it cover?
                  <template v-slot:actions>
                    <v-icon
                      color="#005F6C"
                    >
                      mdi-chevron-down
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <br>
                  Travel insurance helps protect you from certain unexpected and potentially costly events that can occur before or during your trip. Ultimately, it’s meant to help you travel with more peace of mind so you can worry less and focus on enjoying your time. <br><br>
                  Insurance coverage for Trip Cancellation can reimburse you for unused, non-refundable, prepaid trip costs if you need to cancel your trip for a covered reason, such as sickness, job loss, flight cancellation due to adverse weather and more. Similarly, insurance coverage for Trip Interruption can recoup your trip costs if you’re unable to continue your trip because of a covered reason—our plans also provide reimbursement for additional transportation costs to help you return home or rejoin your group. <br><br>
                  Travel insurance provides coverage for other unexpected, out-of-pocket costs as well, such as meals and overnight lodging if your travel is delayed*, clothing and personal items if your baggage is delayed* and medical expenses if you get sick or injured during your trip. Plans also provide insurance coverage for your bags and personal effects during your trip and some offerings help protect your sporting equipment while traveling, along with a variety of other available coverages. We recommend comparing our plans and choosing the option that best suits you and your trip. <br><br>
                  *Travel Delay and Baggage Delay coverages can only be claimed if the delay lasts for the amount of time specified in the <a
                    href="https://www.generalitravelinsurance.com/retrieve-policy.html"
                    target="_new"
                  >Schedule of Benefits</a>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header class=" text-h4 font-weight-bold">
                  How does travel insurance work?
                  <template v-slot:actions>
                    <v-icon
                      color="#005F6C"
                    >
                      mdi-chevron-down
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <br>
                  If something happens during your trip and you need to use your travel insurance, you’ll need to <a
                    href="https://www.generalitravelinsurance.com/start-a-claim/how-to.html"
                    target="_new"
                  >file a claim</a> to be reimbursed. We have a page that explains <a
                    href="https://www.generalitravelinsurance.com/start-a-claim/how-to.html"
                    target="_new"
                  >how to start a claim</a>, a great resource for learning more about the claims process. <br><br>
                  Let’s say your baggage is delayed* and you need to buy clothes and toiletries, you would submit a claim to seek reimbursement. Travel insurance comes in handy in scenarios like this. <br><br>
                  Oftentimes, travel insurance is offered when buying a cruise, airfare or booking a vacation rental. We encourage customers to pay attention to the fine print when considering insurance provided by travel suppliers. For example, a <a
                    href="https://www.generalitravelinsurance.com/travel-resources/why-travel-insurance-is-crucial-for-cruise-trips.html"
                    target="_new"
                  >cruise line’s travel insurance</a> may not pay cash to cover a canceled or interrupted cruise, but instead offers credit towards a future cruise. If you prefer cash reimbursement, that’s one reason to consider purchasing directly from a travel insurance provider instead. <br><br>
                  We recommend bringing a print out of your plan documents with you when traveling so if you are affected by a storm and lose power or can’t connect to the internet, you’ll still know what number to call and what instructions to follow. <br><br>
                  <a
                    href="https://www.generalitravelinsurance.com/travel-resources/how-travel-insurance-works.html"
                    target="_new"
                  >Read more about how travel insurance works</a> <br><br>
                  * Baggage Delay coverage can only be claimed if the delay lasts for the amount of time specified in the <a
                    href="https://www.generalitravelinsurance.com/retrieve-policy.html"
                    target="_new"
                  >Schedule of Benefits</a>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header class=" text-h4 font-weight-bold">
                  What are the benefits of travel insurance?
                  <template v-slot:actions>
                    <v-icon
                      color="#005F6C"
                    >
                      mdi-chevron-down
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <br>
                  Our travel insurance product includes a wide range of benefits with a variety of plans and add-ons to help protect your trip. Our plans can help with a number of unexpected problems, including trip cancellations and interruptions; travel delays; lost, damaged and delayed baggage; and unexpected sickness, injury and medical evacuation. Additionally, our 24-Hour Emergency Services, Concierge and ID Theft Resolution services are included with our plans. These services round out our travel protection plans to provide real-time assistance when you need it on your trip. <br><br>
                  In addition to providing added peace of mind, travel insurance can also help international travelers satisfy passport insurance requirements. Depending upon your country of residence and where you’re traveling to, you may be required to possess passport insurance for emergency hospital treatment and emergency medical evacuation before you’re permitted to enter your destination country or receive your visa.
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header class=" text-h4 font-weight-bold">
                  What can I insure?
                  <template v-slot:actions>
                    <v-icon
                      color="#005F6C"
                    >
                      mdi-chevron-down
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <br>
                  You may insure prepaid, non-refundable trip costs.
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header class=" text-h4 font-weight-bold">
                  When is the best time to get travel insurance?
                  <template v-slot:actions>
                    <v-icon
                      color="#005F6C"
                    >
                      mdi-chevron-down
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <br>
                  We suggest you buy a travel insurance plan when you make your first trip payment, like airfare or a cruise. That way you have a long coverage period in case you need to cancel your trip. Later on, you can <a
                    href="https://www.generalitravelinsurance.com/faqs.html#update"
                    target="_new"
                  >add coverage to the same</a> plan for your hotel, tours and other prepaid, non-refundable trip costs. It’s best to contact us to add coverage soon after you make new trip payments to be sure you have coverage for your full trip cost.
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header class=" text-h4 font-weight-bold">
                  When can I buy travel insurance?
                  <template v-slot:actions>
                    <v-icon
                      color="#005F6C"
                    >
                      mdi-chevron-down
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <br>
                  Our travel insurance plans can be purchased up to 18 months before the trip departure date and up until the day prior to departure.
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
        <!---------------------------------COVERAGE AND POLICY-RELATED FAQs------------------------------------>
        <v-row>
          <div
            class="cov-faq-title"
            @click="toggleClaimsFaq"
          >
            <v-col
              cols="12"
              class="faq-header-desk"
            >
              Coverage and Policy-Related FAQs
              <v-icon
                v-if="!claimsFaq"
                right
                x-large
                color="#005F6C"
              >
                mdi-chevron-right
              </v-icon>
              <v-icon
                v-if="claimsFaq"
                right
                x-large
                color="#005F6C"
              >
                mdi-chevron-down
              </v-icon>
            </v-col>
          </div>
        </v-row>
        <v-row v-if="claimsFaq">
          <v-col>
            <v-expansion-panels
              focusable
              accordion
            >
              <v-expansion-panel>
                <v-expansion-panel-header class=" text-h4 font-weight-bold">
                  I’ve paid for some of my trip, but not all of it. Can I still buy travel insurance?
                  <template v-slot:actions>
                    <v-icon
                      color="#005F6C"
                    >
                      mdi-chevron-down
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <br>
                  Yes, you can actually buy travel insurance from Blanket before you make any trip payments. <br><br>
                  We recommend the purchase of travel insurance immediately upon the initial payment for your reservation. This provides you with a longer coverage period in which cancellation within the required timeframe can be made if you need to cancel your trip. You always have the option to add coverage to your policy for any additional trip costs, such as hotel reservations, booked tours or other prepaid, non-refundable expenses. To confirm that you are properly covered for all of your trip costs we suggest contacting us for assistance at any point you incur additional costs for your trip.
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header class=" text-h4 font-weight-bold">
                  If my trip is delayed past the original return date, does my travel insurance coverage extend as well?
                  <template v-slot:actions>
                    <v-icon
                      color="#005F6C"
                    >
                      mdi-chevron-down
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <br>
                  Yes, your travel insurance coverage will be extended as long as the entire cost of your trip is included in your plan and the delay is caused by circumstances beyond your control. If coverage is extended, coverage will end either on the date you reach your originally scheduled return destination or seven days after your scheduled return date, whichever comes first.
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header class=" text-h4 font-weight-bold">
                  When does my travel protection plan go into effect and when does it end?
                  <template v-slot:actions>
                    <v-icon
                      color="#005F6C"
                    >
                      mdi-chevron-down
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <br>
                  The Trip Cancellation coverage included with your plan goes into effect the day after you purchase travel protection at 12:01 a.m. On your scheduled departure date, once you begin your trip, you can no longer file a claim for Trip Cancellation. <br><br>
                  As soon as you depart, the other coverages in your plan become effective, including Medical and Dental, Trip Interruption, Travel Delay, Emergency Assistance and Transportation, Baggage and more. Those coverages end as soon as one of these occurs: You return from your trip, the trip is completed, your scheduled return date arrives (unless you qualify for <a
                    href="https://www.generalitravelinsurance.com/faqs.html#extension"
                    target="_new"
                  >extension of coverage</a>), or your arrival at the destination on a one-way trip. <br><br>
                  Once you’re home, you can continue to claim for covered Medical and Dental costs that occur within one year from the date of your Sickness or Injury that occurred during your trip. <br><br>
                  A variety of <a
                    href="https://www.generalitravelinsurance.com/travel-resources/services.html"
                    target="_new"
                  >Travel Services</a> are included with your plan. Concierge Services are available to use as soon as you purchase your plan and end when you return. Travel Support Services can be used during your trip. ID Theft Resolution Services are also available for a full 180 days starting on the scheduled departure date.
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header class=" text-h4 font-weight-bold">
                  Can I be covered for a Pre-Existing Medical Condition?
                  <template v-slot:actions>
                    <v-icon
                      color="#005F6C"
                    >
                      mdi-chevron-down
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <br>
                  If you purchase our Premium plan you can qualify for coverage for pre-existing medical conditions, as long as the travel protection plan is: a) purchased prior to or within 24 hours of your final trip payment; b) you are medically able to travel at the time the plan is purchased; and c) all prepaid trip costs that are subject to cancellation penalties or restrictions have been insured. <br><br>
                  At our <a
                    href="https://www.generalitravelinsurance.com/details/travel-insurance-glossary.html#pre-ex"
                    target="_new"
                  >Travel Insurance Definitions page</a>, you can see how we define Pre-Existing Conditions or see a <a
                    href="https://www.generalitravelinsurance.com/retrieve-policy.html"
                    target="_new"
                  >sample Description of Coverage/Policy</a> for full details.
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header class=" text-h4 font-weight-bold">
                  What are the requirements for coverage due to illness?
                  <template v-slot:actions>
                    <v-icon
                      color="#005F6C"
                    >
                      mdi-chevron-down
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <br>
                  In order to qualify for coverage due to illness, including COVID-19: <br><br>
                  You, a traveling companion or your service animal get sick after you purchase your plan, must seek the in person treatment of a physician and can’t travel according to a physician. Or a non-traveling family member is hospitalized after you purchase your plan and you are unable to make the trip. <br><br>
                  Important Note: Your or your traveling companion’s sickness must first occur after you purchase your plan in order to have coverage. Pre-existing medical conditions are generally excluded from coverage. However, coverage is available with the Premium plan if you purchased your plan prior to or within 24 hours of making Final Payment for your trip, provided other requirements are also met. Review a <a
                    href="https://www.generalitravelinsurance.com/retrieve-policy.html"
                    target="_new"
                  >Description of Coverage/Policy</a> for full details. <br><br>
                  <a
                    href="https://www.generalitravelinsurance.com/details/travel-insurance-glossary.html"
                    target="_new"
                  >Read more for additional details</a>.
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header class=" text-h4 font-weight-bold">
                  How does the Trip Cancellation for Any Reason add-on coverage work?
                  <template v-slot:actions>
                    <v-icon
                      color="#005F6C"
                    >
                      mdi-chevron-down
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <br>
                  Trip Cancellation for Any Reason coverage is available as an optional upgrade to our Premium plan. This coverage reimburses you up to 60% of the penalty amount for your trip when you cancel for any reason. These requirements must be met in order to qualify for Trip Cancellation for Any Reason coverage: <br><br>
                  <ul>
                    <li>The travel insurance plan is purchased within 24 hours of initial deposit for your trip</li>
                    <li>You are not disabled from travel at the time you purchase the plan</li>
                    <li>You have insured 100% of all arrangements that are subject to cancellation penalties or restrictions</li>
                    <li>You cancel your trip 48 hours or more prior to the scheduled departure date</li>
                    <li>The trip cost per person is no more than $10,000</li>
                    <li>All insured travelers cancel the trip</li>
                  </ul> <br>
                  This coverage is not available to residents of New York.
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header class=" text-h4 font-weight-bold">
                  How does rental car damage coverage work?
                  <template v-slot:actions>
                    <v-icon
                      color="#005F6C"
                    >
                      mdi-chevron-down
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <br>
                  Rental Car Damage coverage is included with the Premium plan and is available as an optional add-on coverage with the Standard and Preferred plans. It provides coverage if your rental car is damaged from collision, theft, vandalism, natural disasters or any other cause beyond your control. Coverage is provided for either the cost of repairs and rental charges charged by the rental company while the vehicle is being repaired or the <a
                    href="https://www.generalitravelinsurance.com/details/travel-insurance-glossary.html#cash"
                    target="_new"
                  >Actual Cash Value</a> of the car, whichever is less. <br><br>

                  <span class="font-weight-bold">Note:</span> Trucks, trailers, motorcycles and other vehicles are not eligible for coverage. Review your <a
                    href="https://www.generalitravelinsurance.com/retrieve-policy.html"
                    target="_new"
                  >Description of Coverage/Policy</a> for a full list of excluded vehicles. <br><br>
                  This coverage is not available to residents of Texas.
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header class=" text-h4 font-weight-bold">
                  How does travel insurance work with frequent flyer miles?
                  <template v-slot:actions>
                    <v-icon
                      color="#005F6C"
                    >
                      mdi-chevron-down
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <br>
                  If you paid for air transportation with frequent flyer miles and cancel your trip, you may be reimbursed for the cost to reinstate the frequent flyer miles to your account. This is only available for Trip Cancellation coverage, not Trip Interruption or other coverages. If frequent flyer miles were used to arrange anything other than air transportation, such as lodging accommodations, our plans do not provide coverage for those situations.
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header class=" text-h4 font-weight-bold">
                  Who underwrites Blanket’s travel insurance?
                  <template v-slot:actions>
                    <v-icon
                      color="#005F6C"
                    >
                      mdi-chevron-down
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <br>
                  Blanket's travel insurance is underwritten by Generali Global Assistance. Generali Global Assistance is a full-service travel insurance and assistance provider. Formerly doing business as <a
                    href="https://www.generalitravelinsurance.com/csa-travel-protection.html"
                    target="_new"
                  >CSA Travel Protection</a>, our customers will receive the same quality plans and outstanding customer service they have come to expect from us over the past 25 years, now backed by one of the largest insurance companies in the world. Our plans are built on the diverse needs of today’s travelers, designed to help you travel smarter. <br><br>
                  Generali Global Assistance is part of the multinational Generali Group, which for more than 185 years has built a presence in 60 countries with over 76,000 employees. We pride ourselves on delivering a simpler, smarter approach to insuring and servicing millions of customers each year. <br><br>
                  <a
                    href="https://www.generalitravelinsurance.com/why-generali.html"
                    target="_newet"
                  >Read more about Generali</a>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
        <!---------------------------------INSURANCE TERMS FAQS------------------------------------>
        <v-row>
          <div
            class="cov-faq-title"
            @click="toggleAdditionalCovFaq"
          >
            <v-col
              cols="12"
              class="faq-header-desk"
            >
              Insurance Terms FAQs
              <v-icon
                v-if="!additionalCovFaq"
                right
                x-large
                color="#005F6C"
              >
                mdi-chevron-right
              </v-icon>
              <v-icon
                v-if="additionalCovFaq"
                right
                x-large
                color="#005F6C"
              >
                mdi-chevron-down
              </v-icon>
            </v-col>
          </div>
        </v-row>
        <v-row v-if="additionalCovFaq">
          <v-col>
            <v-expansion-panels
              focusable
              accordion
            >
              <v-expansion-panel>
                <v-expansion-panel-header class=" text-h4 font-weight-bold">
                  What does "final payment" mean?
                  <template v-slot:actions>
                    <v-icon
                      color="#005F6C"
                    >
                      mdi-chevron-down
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <br>
                  "Final payment" is the last pre-paid payment (prior to departure) of the most expensive part of the trip, unless the main air arrangements are added last. "Main air arrangements" refer to air to/from the main destination, compared to tickets for a side trip during the vacation.
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header class=" text-h4 font-weight-bold">
                  What is a foreseeable event?
                  <template v-slot:actions>
                    <v-icon
                      color="#005F6C"
                    >
                      mdi-chevron-down
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <br>
                  Foreseeable simply means reasonably known beforehand. Once it is reasonable that people traveling to an area would know about an event, it becomes foreseeable. For example, if employees of the airline you are flying announce that they are going on strike, the event becomes foreseeable once they make the announcement.
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header class=" text-h4 font-weight-bold">
                  What are non-refundable, pre-paid trip costs?
                  <template v-slot:actions>
                    <v-icon
                      color="#005F6C"
                    >
                      mdi-chevron-down
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <br>
                  Non-refundable, pre-paid trip costs are costs associated with your insured trip that you wouldn't recoup if you cancel the trip and are paid before you leave on your trip. Take into account flights, cruises, accommodations, tours, entertainment and other trip costs that could be lost if you're prevented from taking your trip.
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
        <!---------------------------------SPECIFIC-ILLNESS FAQs------------------------------------>
        <v-row>
          <div
            class="cov-faq-title"
            @click="toggleMoreCommonFaq"
          >
            <v-col
              cols="12"
              class="faq-header-desk"
            >
              Specific Illness FAQs
              <v-icon
                v-if="!moreCommonFaq"
                right
                x-large
                color="#005F6C"
              >
                mdi-chevron-right
              </v-icon>
              <v-icon
                v-if="moreCommonFaq"
                right
                x-large
                color="#005F6C"
              >
                mdi-chevron-down
              </v-icon>
            </v-col>
          </div>
        </v-row>
        <v-row v-if="moreCommonFaq">
          <v-col>
            <v-expansion-panels
              focusable
              accordion
            >
              <v-expansion-panel>
                <v-expansion-panel-header class=" text-h4 font-weight-bold">
                  Can Monkeypox be covered by travel insurance?
                  <template v-slot:actions>
                    <v-icon
                      color="#005F6C"
                    >
                      mdi-chevron-down
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <br>
                  Yes, Monkeypox is treated like any other illness and can be covered by Blanket travel insurance. <br><br>
                  Recently, an outbreak of Monkeypox has spread across several countries that don’t normally report monkeypox, including the United States. Monkeypox symptoms include fever, muscle aches, chills, swollen lymph glands and “a rash that can look like pimples or blisters that appears on the face, inside the mouth, and on other parts of the body” according to the Centers for Disease Control. The virus spreads through close contact with someone who has Monkeypox. <br><br>
                  If you, a family member or a traveling companion are diagnosed with Monkeypox before or during your trip, and meet the requirements for coverage due to sickness, you can be covered for Trip Cancellation, Trip Interruption, Travel Delay, Medical & Dental, and Emergency Assistance & Transportation, in addition to our 24/7 Emergency Assistance services. You can also be covered for additional lodging expenses and extension of your travel insurance plan if you are required to isolate at your destination and your return is delayed. <br><br>
                  For your safety and the safety of others, insured travelers who believe they may have contracted the virus should call our <a
                    href="https://www.generalitravelinsurance.com/contact-us.html"
                    target="_new"
                  >24/7 Emergency Assistance team</a> who will coordinate local treatment on your behalf.
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </div>
    </div>
    <!------------------------------------------TABLET------------------------------------------------------>
    <div v-show="tabletDevice">
      <hero-component
        align="center"
        btn-location="below"
        btn-link="/travel/infousa"
        btn-text="Get started on your travel policy"
        background-url="/img/Travel-US-Background-Desktop.jpg"
      >
        We’ve got your <br /> adventurous<br /> side, covered.
      </hero-component>
      <div class="section2-tablet pb-16 px-2">
        <div class="s2-title">
          <v-row class="d-flex justify-center">
            <v-col
              cols="12"
              class="d-flex justify-center"
            >
              <div class="section2-header">
                Protect your travel adventures with Blanket
              </div>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col class="d-flex justify-center">
              <div class="s2-sub">
                Get peace of mind for your best laid plans.
              </div>
            </v-col>
          </v-row>
        </div>
        <v-row class="d-flex align-start justify-center mt-16 mb-n6">
          <v-col
            cols="12"
            class="d-inline-flex"
          >
            <v-col
              class="d-flex justify-center"
            >
              <v-icon
                class="primary--text s2-icon"
              >
                mdi-calendar-check
              </v-icon>
            </v-col>
            <v-col
              class="d-flex justify-center"
            >
              <v-icon
                class="primary--text s2-icon"
              >
                mdi-shield-star
              </v-icon>
            </v-col>
            <v-col
              class="d-flex justify-center"
            >
              <v-icon
                class="primary--text s2-icon"
              >
                mdi-hospital-building
              </v-icon>
            </v-col>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center mt-0 py-0">
          <v-col
            cols="12"
            class="d-inline-flex align-center"
          >
            <v-col class="d-flex justify-center">
              <div class="s2-subhead">
                Plan your next<br> trip with confidence
              </div>
            </v-col>
            <v-col class="d-flex justify-center">
              <div class="s2-subhead">
                10-Day Free Look <br> Period
              </div>
            </v-col>
            <v-col class="d-flex justify-center">
              <div class="s2-subhead">
                Covid-19 Illness <br> Coverage
              </div>
            </v-col>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center mt-0">
          <v-col
            cols="12"
            class="d-inline-flex"
          >
            <v-col class="d-flex justify-center s2-desc">
              We’ll protect against unforeseen events that could cancel or interrupt your vacation resulting in the loss of any pre-paid, non-refundable trip costs.
            </v-col>
            <v-col class="d-flex justify-center s2-desc">
              Not sure if a travel insurance plan is right for you, but want to take advantage of time-sensitive benefits? Put your plan in place today and take advantage of our 10-Day Free Look Period refund policy.
            </v-col>
            <v-col class="d-flex justify-center s2-desc">
              Before you go and during your trip, travel insurance from Blanket provides coverage for COVID-19 sickness — complete with expert 24/7 worldwide assistance while you’re away, so you can wander more and worry less.
            </v-col>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            class="d-flex justify-center"
            cols="12"
          >
            <v-btn
              class="mint text-h4 font-weight-bold darkgrey--text"
              large
              to="/travel/infousa"
            >
              Apply Now
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <div class="section4 pt-16">
        <v-row class="d-flex justify-start">
          <v-col>
            <v-row>
              <v-col
                cols="12"
                lg="10"
                xl="8"
                class="pl-0"
              >
                <div class="sec4-title">
                  Your (mis)adventures, covered.
                </div>
              </v-col>
            </v-row>
            <v-row class="d-flex justify-start">
              <v-col
                cols="12"
                lg="12"
                xl="8"
                class="pt-10"
              >
                <div class="sec4-sub">
                  When an unforeseen event happens and it interferes with your travel itinerary, our travel insurance plan covers you. Here's how:
                </div>
              </v-col>
            </v-row>

            <v-row class="d-flex justify-center">
              <v-col
                cols="12"
                class="d-flex justify-start align-start covg-txt"
              >
                <v-icon
                  left
                  large
                  class="primary--text"
                >
                  mdi-check-bold
                </v-icon>
                Our plans provide for trip cancellation reimbursement for the unused, non-refundable, prepaid trip costs if you are forced to cancel your trip for any of the numerous covered reasons.
              </v-col>
              <v-col
                cols="12"
                class="d-flex justify-start align-start covg-txt"
              >
                <v-icon
                  left
                  large
                  class="primary--text"
                >
                  mdi-check-bold
                </v-icon>
                Our plans include trip interruption and missed connection reimbursement for the unused portion of any non-refundable, pre-paid trip expense when situations like a medical emergency or a weather-related event results in a canceled flight or missed connection.
              </v-col>
              <v-col
                cols="12"
                class="d-flex justify-start align-start covg-txt"
              >
                <v-icon
                  left
                  large
                  class="primary--text"
                >
                  mdi-check-bold
                </v-icon>
                Medical emergency expenses can be covered if you’re injured or become sick while traveling and have to seek treatment. If the situation requires medical evacuation, that can also be covered, up to coverage limits.
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
      <div class="sec5-dsk">
        <v-row>
          <v-col class="sec5-title">
            We’ve got easy covered.
          </v-col>
        </v-row>
        <v-row>
          <v-col class="sec5-sub">
            Get Travel coverage in less time than it takes to clean your sunglasses.
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              large
              class="mint apply-btn ml-4"
              to="/travel/infousa"
            >
              <span class="text-h4 font-weight-bold darkgrey--text">Get your travel policy today</span>
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <div class="faq-sec-desk pt-16 pb-16">
        <!---------------------------------BUYING TRAVEL INSURANCE FAQs------------------------------------>
        <v-row>
          <div
            class="cov-faq-title"
            @click="toggleCovFaq"
          >
            <v-col
              cols="12"
              class="faq-header-tablet"
            >
              Buying Travel Insurance FAQs
              <v-icon
                v-if="!covFaq"
                right
                x-large
                color="#005F6C"
              >
                mdi-chevron-right
              </v-icon>
              <v-icon
                v-if="covFaq"
                right
                x-large
                color="#005F6C"
              >
                mdi-chevron-down
              </v-icon>
            </v-col>
          </div>
        </v-row>
        <v-row v-if="covFaq">
          <v-col>
            <v-expansion-panels
              focusable
              accordion
            >
              <v-expansion-panel>
                <v-expansion-panel-header class=" text-h4 font-weight-bold">
                  What is travel insurance and what does it cover?
                  <template v-slot:actions>
                    <v-icon
                      color="#005F6C"
                    >
                      mdi-chevron-down
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <br>
                  Travel insurance helps protect you from certain unexpected and potentially costly events that can occur before or during your trip. Ultimately, it’s meant to help you travel with more peace of mind so you can worry less and focus on enjoying your time. <br><br>
                  Insurance coverage for Trip Cancellation can reimburse you for unused, non-refundable, prepaid trip costs if you need to cancel your trip for a covered reason, such as sickness, job loss, flight cancellation due to adverse weather and more. Similarly, insurance coverage for Trip Interruption can recoup your trip costs if you’re unable to continue your trip because of a covered reason—our plans also provide reimbursement for additional transportation costs to help you return home or rejoin your group. <br><br>
                  Travel insurance provides coverage for other unexpected, out-of-pocket costs as well, such as meals and overnight lodging if your travel is delayed*, clothing and personal items if your baggage is delayed* and medical expenses if you get sick or injured during your trip. Plans also provide insurance coverage for your bags and personal effects during your trip and some offerings help protect your sporting equipment while traveling, along with a variety of other available coverages. We recommend comparing our plans and choosing the option that best suits you and your trip. <br><br>
                  *Travel Delay and Baggage Delay coverages can only be claimed if the delay lasts for the amount of time specified in the <a
                    href="https://www.generalitravelinsurance.com/retrieve-policy.html"
                    target="_new"
                  >Schedule of Benefits</a>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header class=" text-h4 font-weight-bold">
                  How does travel insurance work?
                  <template v-slot:actions>
                    <v-icon
                      color="#005F6C"
                    >
                      mdi-chevron-down
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <br>
                  If something happens during your trip and you need to use your travel insurance, you’ll need to <a
                    href="https://www.generalitravelinsurance.com/start-a-claim/how-to.html"
                    target="_new"
                  >file a claim</a> to be reimbursed. We have a page that explains <a
                    href="https://www.generalitravelinsurance.com/start-a-claim/how-to.html"
                    target="_new"
                  >how to start a claim</a>, a great resource for learning more about the claims process. <br><br>
                  Let’s say your baggage is delayed* and you need to buy clothes and toiletries, you would submit a claim to seek reimbursement. Travel insurance comes in handy in scenarios like this. <br><br>
                  Oftentimes, travel insurance is offered when buying a cruise, airfare or booking a vacation rental. We encourage customers to pay attention to the fine print when considering insurance provided by travel suppliers. For example, a <a
                    href="https://www.generalitravelinsurance.com/travel-resources/why-travel-insurance-is-crucial-for-cruise-trips.html"
                    target="_new"
                  >cruise line’s travel insurance</a> may not pay cash to cover a canceled or interrupted cruise, but instead offers credit towards a future cruise. If you prefer cash reimbursement, that’s one reason to consider purchasing directly from a travel insurance provider instead. <br><br>
                  We recommend bringing a print out of your plan documents with you when traveling so if you are affected by a storm and lose power or can’t connect to the internet, you’ll still know what number to call and what instructions to follow. <br><br>
                  <a
                    href="https://www.generalitravelinsurance.com/travel-resources/how-travel-insurance-works.html"
                    target="_new"
                  >Read more about how travel insurance works</a> <br><br>
                  * Baggage Delay coverage can only be claimed if the delay lasts for the amount of time specified in the <a
                    href="https://www.generalitravelinsurance.com/retrieve-policy.html"
                    target="_new"
                  >Schedule of Benefits</a>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header class=" text-h4 font-weight-bold">
                  What are the benefits of travel insurance?
                  <template v-slot:actions>
                    <v-icon
                      color="#005F6C"
                    >
                      mdi-chevron-down
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <br>
                  Our travel insurance product includes a wide range of benefits with a variety of plans and add-ons to help protect your trip. Our plans can help with a number of unexpected problems, including trip cancellations and interruptions; travel delays; lost, damaged and delayed baggage; and unexpected sickness, injury and medical evacuation. Additionally, our 24-Hour Emergency Services, Concierge and ID Theft Resolution services are included with our plans. These services round out our travel protection plans to provide real-time assistance when you need it on your trip. <br><br>
                  In addition to providing added peace of mind, travel insurance can also help international travelers satisfy passport insurance requirements. Depending upon your country of residence and where you’re traveling to, you may be required to possess passport insurance for emergency hospital treatment and emergency medical evacuation before you’re permitted to enter your destination country or receive your visa.
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header class=" text-h4 font-weight-bold">
                  What can I insure?
                  <template v-slot:actions>
                    <v-icon
                      color="#005F6C"
                    >
                      mdi-chevron-down
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <br>
                  You may insure prepaid, non-refundable trip costs.
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header class=" text-h4 font-weight-bold">
                  When is the best time to get travel insurance?
                  <template v-slot:actions>
                    <v-icon
                      color="#005F6C"
                    >
                      mdi-chevron-down
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <br>
                  We suggest you buy a travel insurance plan when you make your first trip payment, like airfare or a cruise. That way you have a long coverage period in case you need to cancel your trip. Later on, you can <a
                    href="https://www.generalitravelinsurance.com/faqs.html#update"
                    target="_new"
                  >add coverage to the same</a> plan for your hotel, tours and other prepaid, non-refundable trip costs. It’s best to contact us to add coverage soon after you make new trip payments to be sure you have coverage for your full trip cost.
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header class=" text-h4 font-weight-bold">
                  When can I buy travel insurance?
                  <template v-slot:actions>
                    <v-icon
                      color="#005F6C"
                    >
                      mdi-chevron-down
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <br>
                  Our travel insurance plans can be purchased up to 18 months before the trip departure date and up until the day prior to departure.
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
        <!---------------------------------COVERAGE AND POLICY-RELATED FAQs------------------------------------>
        <v-row>
          <div
            class="cov-faq-title"
            @click="toggleClaimsFaq"
          >
            <v-col
              cols="12"
              class="faq-header-tablet"
            >
              Coverage and Policy-Related FAQs
              <v-icon
                v-if="!claimsFaq"
                right
                x-large
                color="#005F6C"
              >
                mdi-chevron-right
              </v-icon>
              <v-icon
                v-if="claimsFaq"
                right
                x-large
                color="#005F6C"
              >
                mdi-chevron-down
              </v-icon>
            </v-col>
          </div>
        </v-row>
        <v-row v-if="claimsFaq">
          <v-col>
            <v-expansion-panels
              focusable
              accordion
            >
              <v-expansion-panel>
                <v-expansion-panel-header class=" text-h4 font-weight-bold">
                  I’ve paid for some of my trip, but not all of it. Can I still buy travel insurance?
                  <template v-slot:actions>
                    <v-icon
                      color="#005F6C"
                    >
                      mdi-chevron-down
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <br>
                  Yes, you can actually buy travel insurance from Blanket before you make any trip payments. <br><br>
                  We recommend the purchase of travel insurance immediately upon the initial payment for your reservation. This provides you with a longer coverage period in which cancellation within the required timeframe can be made if you need to cancel your trip. You always have the option to add coverage to your policy for any additional trip costs, such as hotel reservations, booked tours or other prepaid, non-refundable expenses. To confirm that you are properly covered for all of your trip costs we suggest contacting us for assistance at any point you incur additional costs for your trip.
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header class=" text-h4 font-weight-bold">
                  If my trip is delayed past the original return date, does my travel insurance coverage extend as well?
                  <template v-slot:actions>
                    <v-icon
                      color="#005F6C"
                    >
                      mdi-chevron-down
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <br>
                  Yes, your travel insurance coverage will be extended as long as the entire cost of your trip is included in your plan and the delay is caused by circumstances beyond your control. If coverage is extended, coverage will end either on the date you reach your originally scheduled return destination or seven days after your scheduled return date, whichever comes first.
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header class=" text-h4 font-weight-bold">
                  When does my travel protection plan go into effect and when does it end?
                  <template v-slot:actions>
                    <v-icon
                      color="#005F6C"
                    >
                      mdi-chevron-down
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <br>
                  The Trip Cancellation coverage included with your plan goes into effect the day after you purchase travel protection at 12:01 a.m. On your scheduled departure date, once you begin your trip, you can no longer file a claim for Trip Cancellation. <br><br>
                  As soon as you depart, the other coverages in your plan become effective, including Medical and Dental, Trip Interruption, Travel Delay, Emergency Assistance and Transportation, Baggage and more. Those coverages end as soon as one of these occurs: You return from your trip, the trip is completed, your scheduled return date arrives (unless you qualify for <a
                    href="https://www.generalitravelinsurance.com/faqs.html#extension"
                    target="_new"
                  >extension of coverage</a>), or your arrival at the destination on a one-way trip. <br><br>
                  Once you’re home, you can continue to claim for covered Medical and Dental costs that occur within one year from the date of your Sickness or Injury that occurred during your trip. <br><br>
                  A variety of <a
                    href="https://www.generalitravelinsurance.com/travel-resources/services.html"
                    target="_new"
                  >Travel Services</a> are included with your plan. Concierge Services are available to use as soon as you purchase your plan and end when you return. Travel Support Services can be used during your trip. ID Theft Resolution Services are also available for a full 180 days starting on the scheduled departure date.
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header class=" text-h4 font-weight-bold">
                  Can I be covered for a Pre-Existing Medical Condition?
                  <template v-slot:actions>
                    <v-icon
                      color="#005F6C"
                    >
                      mdi-chevron-down
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <br>
                  If you purchase our Premium plan you can qualify for coverage for pre-existing medical conditions, as long as the travel protection plan is: a) purchased prior to or within 24 hours of your final trip payment; b) you are medically able to travel at the time the plan is purchased; and c) all prepaid trip costs that are subject to cancellation penalties or restrictions have been insured. <br><br>
                  At our <a
                    href="https://www.generalitravelinsurance.com/details/travel-insurance-glossary.html#pre-ex"
                    target="_new"
                  >Travel Insurance Definitions page</a>, you can see how we define Pre-Existing Conditions or see a <a
                    href="https://www.generalitravelinsurance.com/retrieve-policy.html"
                    target="_new"
                  >sample Description of Coverage/Policy</a> for full details.
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header class=" text-h4 font-weight-bold">
                  What are the requirements for coverage due to illness?
                  <template v-slot:actions>
                    <v-icon
                      color="#005F6C"
                    >
                      mdi-chevron-down
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <br>
                  In order to qualify for coverage due to illness, including COVID-19: <br><br>
                  You, a traveling companion or your service animal get sick after you purchase your plan, must seek the in person treatment of a physician and can’t travel according to a physician. Or a non-traveling family member is hospitalized after you purchase your plan and you are unable to make the trip. <br><br>
                  Important Note: Your or your traveling companion’s sickness must first occur after you purchase your plan in order to have coverage. Pre-existing medical conditions are generally excluded from coverage. However, coverage is available with the Premium plan if you purchased your plan prior to or within 24 hours of making Final Payment for your trip, provided other requirements are also met. Review a <a
                    href="https://www.generalitravelinsurance.com/retrieve-policy.html"
                    target="_new"
                  >Description of Coverage/Policy</a> for full details. <br><br>
                  <a
                    href="https://www.generalitravelinsurance.com/details/travel-insurance-glossary.html"
                    target="_new"
                  >Read more for additional details</a>.
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header class=" text-h4 font-weight-bold">
                  How does the Trip Cancellation for Any Reason add-on coverage work?
                  <template v-slot:actions>
                    <v-icon
                      color="#005F6C"
                    >
                      mdi-chevron-down
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <br>
                  Trip Cancellation for Any Reason coverage is available as an optional upgrade to our Premium plan. This coverage reimburses you up to 60% of the penalty amount for your trip when you cancel for any reason. These requirements must be met in order to qualify for Trip Cancellation for Any Reason coverage: <br><br>
                  <ul>
                    <li>The travel insurance plan is purchased within 24 hours of initial deposit for your trip</li>
                    <li>You are not disabled from travel at the time you purchase the plan</li>
                    <li>You have insured 100% of all arrangements that are subject to cancellation penalties or restrictions</li>
                    <li>You cancel your trip 48 hours or more prior to the scheduled departure date</li>
                    <li>The trip cost per person is no more than $10,000</li>
                    <li>All insured travelers cancel the trip</li>
                  </ul> <br>
                  This coverage is not available to residents of New York.
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header class=" text-h4 font-weight-bold">
                  How does rental car damage coverage work?
                  <template v-slot:actions>
                    <v-icon
                      color="#005F6C"
                    >
                      mdi-chevron-down
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <br>
                  Rental Car Damage coverage is included with the Premium plan and is available as an optional add-on coverage with the Standard and Preferred plans. It provides coverage if your rental car is damaged from collision, theft, vandalism, natural disasters or any other cause beyond your control. Coverage is provided for either the cost of repairs and rental charges charged by the rental company while the vehicle is being repaired or the <a
                    href="https://www.generalitravelinsurance.com/details/travel-insurance-glossary.html#cash"
                    target="_new"
                  >Actual Cash Value</a> of the car, whichever is less. <br><br>

                  <span class="font-weight-bold">Note:</span> Trucks, trailers, motorcycles and other vehicles are not eligible for coverage. Review your <a
                    href="https://www.generalitravelinsurance.com/retrieve-policy.html"
                    target="_new"
                  >Description of Coverage/Policy</a> for a full list of excluded vehicles. <br><br>
                  This coverage is not available to residents of Texas.
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header class=" text-h4 font-weight-bold">
                  How does travel insurance work with frequent flyer miles?
                  <template v-slot:actions>
                    <v-icon
                      color="#005F6C"
                    >
                      mdi-chevron-down
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <br>
                  If you paid for air transportation with frequent flyer miles and cancel your trip, you may be reimbursed for the cost to reinstate the frequent flyer miles to your account. This is only available for Trip Cancellation coverage, not Trip Interruption or other coverages. If frequent flyer miles were used to arrange anything other than air transportation, such as lodging accommodations, our plans do not provide coverage for those situations.
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header class=" text-h4 font-weight-bold">
                  Who underwrites Blanket’s travel insurance?
                  <template v-slot:actions>
                    <v-icon
                      color="#005F6C"
                    >
                      mdi-chevron-down
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <br>
                  Blanket's travel insurance is underwritten by Generali Global Assistance. Generali Global Assistance is a full-service travel insurance and assistance provider. Formerly doing business as <a
                    href="https://www.generalitravelinsurance.com/csa-travel-protection.html"
                    target="_new"
                  >CSA Travel Protection</a>, our customers will receive the same quality plans and outstanding customer service they have come to expect from us over the past 25 years, now backed by one of the largest insurance companies in the world. Our plans are built on the diverse needs of today’s travelers, designed to help you travel smarter. <br><br>
                  Generali Global Assistance is part of the multinational Generali Group, which for more than 185 years has built a presence in 60 countries with over 76,000 employees. We pride ourselves on delivering a simpler, smarter approach to insuring and servicing millions of customers each year. <br><br>
                  <a
                    href="https://www.generalitravelinsurance.com/why-generali.html"
                    target="_newet"
                  >Read more about Generali</a>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
        <!---------------------------------INSURANCE TERMS FAQS------------------------------------>
        <v-row>
          <div
            class="cov-faq-title"
            @click="toggleAdditionalCovFaq"
          >
            <v-col
              cols="12"
              class="faq-header-tablet"
            >
              Insurance Terms FAQs
              <v-icon
                v-if="!additionalCovFaq"
                right
                x-large
                color="#005F6C"
              >
                mdi-chevron-right
              </v-icon>
              <v-icon
                v-if="additionalCovFaq"
                right
                x-large
                color="#005F6C"
              >
                mdi-chevron-down
              </v-icon>
            </v-col>
          </div>
        </v-row>
        <v-row v-if="additionalCovFaq">
          <v-col>
            <v-expansion-panels
              focusable
              accordion
            >
              <v-expansion-panel>
                <v-expansion-panel-header class=" text-h4 font-weight-bold">
                  What does "final payment" mean?
                  <template v-slot:actions>
                    <v-icon
                      color="#005F6C"
                    >
                      mdi-chevron-down
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <br>
                  "Final payment" is the last pre-paid payment (prior to departure) of the most expensive part of the trip, unless the main air arrangements are added last. "Main air arrangements" refer to air to/from the main destination, compared to tickets for a side trip during the vacation.
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header class=" text-h4 font-weight-bold">
                  What is a foreseeable event?
                  <template v-slot:actions>
                    <v-icon
                      color="#005F6C"
                    >
                      mdi-chevron-down
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <br>
                  Foreseeable simply means reasonably known beforehand. Once it is reasonable that people traveling to an area would know about an event, it becomes foreseeable. For example, if employees of the airline you are flying announce that they are going on strike, the event becomes foreseeable once they make the announcement.
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header class=" text-h4 font-weight-bold">
                  What are non-refundable, pre-paid trip costs?
                  <template v-slot:actions>
                    <v-icon
                      color="#005F6C"
                    >
                      mdi-chevron-down
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <br>
                  Non-refundable, pre-paid trip costs are costs associated with your insured trip that you wouldn't recoup if you cancel the trip and are paid before you leave on your trip. Take into account flights, cruises, accommodations, tours, entertainment and other trip costs that could be lost if you're prevented from taking your trip.
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
        <!---------------------------------SPECIFIC-ILLNESS FAQs------------------------------------>
        <v-row>
          <div
            class="cov-faq-title"
            @click="toggleMoreCommonFaq"
          >
            <v-col
              cols="12"
              class="faq-header-tablet"
            >
              Specific Illness FAQs
              <v-icon
                v-if="!moreCommonFaq"
                right
                x-large
                color="#005F6C"
              >
                mdi-chevron-right
              </v-icon>
              <v-icon
                v-if="moreCommonFaq"
                right
                x-large
                color="#005F6C"
              >
                mdi-chevron-down
              </v-icon>
            </v-col>
          </div>
        </v-row>
        <v-row v-if="moreCommonFaq">
          <v-col>
            <v-expansion-panels
              focusable
              accordion
            >
              <v-expansion-panel>
                <v-expansion-panel-header class=" text-h4 font-weight-bold">
                  Can Monkeypox be covered by travel insurance?
                  <template v-slot:actions>
                    <v-icon
                      color="#005F6C"
                    >
                      mdi-chevron-down
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <br>
                  Yes, Monkeypox is treated like any other illness and can be covered by Blanket travel insurance. <br><br>
                  Recently, an outbreak of Monkeypox has spread across several countries that don’t normally report monkeypox, including the United States. Monkeypox symptoms include fever, muscle aches, chills, swollen lymph glands and “a rash that can look like pimples or blisters that appears on the face, inside the mouth, and on other parts of the body” according to the Centers for Disease Control. The virus spreads through close contact with someone who has Monkeypox. <br><br>
                  If you, a family member or a traveling companion are diagnosed with Monkeypox before or during your trip, and meet the requirements for coverage due to sickness, you can be covered for Trip Cancellation, Trip Interruption, Travel Delay, Medical & Dental, and Emergency Assistance & Transportation, in addition to our 24/7 Emergency Assistance services. You can also be covered for additional lodging expenses and extension of your travel insurance plan if you are required to isolate at your destination and your return is delayed. <br><br>
                  For your safety and the safety of others, insured travelers who believe they may have contracted the virus should call our <a
                    href="https://www.generalitravelinsurance.com/contact-us.html"
                    target="_new"
                  >24/7 Emergency Assistance team</a> who will coordinate local treatment on your behalf.
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </div>
    </div>
    <!------------------------------------------MOBILE------------------------------------------------------>
    <div v-if="mobileDevice">
      <div
        class="section1-mobile"
      >
        <div class="text-center pt-12">
        </div>
      </div>
      <div class="section2-mobile">
        <div class="section2-title-mobile">
          <v-row class="mb-5">
            <v-col class="pt-5">
              We’ve got your <br>
              adventurous<br>
              side, covered.
            </v-col>
            <v-col class="pt-5">
              <v-btn
                x-large
                class="mint mx-auto py-8 mr-0"
                to="/travel/infousa"
              >
                <span class="section2-btn-txt-mobile pt-1">get started on <br> your travel policy</span>
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="section3-mobile">
        <v-row class="pt-10">
          <v-col class="section3-title-mobile">
            Protect your travel adventures with Blanket
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="section3-sub-mobile">
            Get peace of mind for your best laid plans.
          </v-col>
        </v-row>
        <v-row class="mt-6">
          <v-col class="text-center">
            <v-icon
              class="primary--text"
              large
            >
              mdi-calendar-check
            </v-icon>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="covg-title-mobile">
            Plan your next<br> trip with confidence
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="covg-txt-mobile text-center">
            We’ll protect against unforeseen events that could cancel or interrupt your vacation resulting in the loss of any pre-paid, non-refundable trip costs.
          </v-col>
        </v-row>
        <v-row class="mt-6">
          <v-col class="text-center">
            <v-icon
              large
              class="primary--text"
            >
              mdi-shield-star
            </v-icon>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="covg-title-mobile">
            10-Day Free Look Period
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="covg-txt-mobile text-center">
            Not sure if a travel insurance plan is right for you, but want to take advantage of time-sensitive benefits? Put your plan in place today and take advantage of our 10-Day Free Look Period refund policy.
          </v-col>
        </v-row>
        <v-row class="mt-6">
          <v-col class="text-center">
            <v-icon
              class="primary--text"
              large
            >
              mdi-hospital-building
            </v-icon>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="covg-title-mobile">
            Covid-19 Illness Coverage
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="covg-txt-mobile text-center">
            Before you go and during your trip, travel insurance from Blanket provides coverage for COVID-19 sickness — complete with expert 24/7 worldwide assistance while you’re away, so you can wander more and worry less.
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center">
          <v-col class="d-flex justify-center">
            <v-btn
              class="mint darkgrey--text text-h4 font-weight-bold mr-0"
              to="/travel/infousa"
            >
              apply now
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <div class="section4-mobile pb-10">
        <v-row class="pt-8">
          <v-col class="s4-title-mobile">
            Your (mis)adventures, covered.
          </v-col>
        </v-row>
        <v-row>
          <v-col class="s4-sub-mobile">
            When an unforeseen event happens and it interferes with your travel itinerary, our travel insurance plan covers you. Here's how:
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            class="d-inline-flex align-center py-0"
          >
            <v-col
              cols="1"
              class="d-flex align-self-start pl-0 py-0"
            >
              <v-icon
                class="primary--text"
                left
              >
                mdi-check-bold
              </v-icon>
            </v-col>
            <v-col
              cols="11"
              class="covg-txt-mobile py-0"
            >
              Our plans provide for trip cancellation reimbursement for the unused, non-refundable, prepaid trip costs if you are forced to cancel your trip for any of the numerous covered reasons
            </v-col>
          </v-col>
          <v-col
            cols="12"
            class="d-inline-flex align-center pb-0"
          >
            <v-col
              cols="1"
              class="d-flex align-self-start pl-0 py-0"
            >
              <v-icon
                class="primary--text"
                left
              >
                mdi-check-bold
              </v-icon>
            </v-col>
            <v-col
              cols="11"
              class="covg-txt-mobile py-0"
            >
              Our plans include trip interruption and missed connection reimbursement for the unused portion of any non-refundable, pre-paid trip expense when situations like a medical emergency or a weather-related event results in a canceled flight or missed connection
            </v-col>
          </v-col>
          <v-col
            cols="12"
            class="d-inline-flex align-center pb-0"
          >
            <v-col
              cols="1"
              class="d-flex align-self-start pl-0 py-0"
            >
              <v-icon
                class="primary--text"
                left
              >
                mdi-check-bold
              </v-icon>
            </v-col>
            <v-col
              cols="11"
              class="covg-txt-mobile py-0"
            >
              Medical emergency expenses can be covered if you’re injured or become sick while traveling and have to seek treatment. If the situation requires medical evacuation, that can also be covered, up to coverage limits.
            </v-col>
          </v-col>
        </v-row>
      </div>
      <div class="s5-mobile">
        <v-row class="pt-10">
          <v-col class="s5-title-mobile">
            We’ve got easy covered.
          </v-col>
        </v-row>
        <v-row>
          <v-col class="s5-sub-mobile">
            Get Travel coverage in less time than it takes to clean your sunglasses.
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex justify-center pt-6">
            <v-btn
              x-large
              class="mint mx-auto py-8"
              to="/travel/infousa"
            >
              <span class="section2-btn-txt-mobile pt-1">get your travel <br> policy today</span>
            </v-btn>
          </v-col>
        </v-row>
        <v-img
          class="phone-mobile"
          src="/img/phone-mobile.png"
        ></v-img>
      </div>
      <div class="faq-sec-desk pt-16 pb-16">
        <!---------------------------------BUYING TRAVEL INSURANCE FAQs------------------------------------>
        <v-row>
          <div
            class="cov-faq-title"
            @click="toggleCovFaq"
          >
            <v-col
              cols="12"
              class="faq-header-mobile d-flex align-center"
            >
              Buying Travel Insurance FAQs
              <v-icon
                v-if="!covFaq"
                right
                x-large
                color="#005F6C"
              >
                mdi-chevron-right
              </v-icon>
              <v-icon
                v-if="covFaq"
                right
                x-large
                color="#005F6C"
              >
                mdi-chevron-down
              </v-icon>
            </v-col>
          </div>
        </v-row>
        <v-row v-if="covFaq">
          <v-col>
            <v-expansion-panels
              focusable
              accordion
            >
              <v-expansion-panel>
                <v-expansion-panel-header class=" text-h4 font-weight-bold">
                  What is travel insurance and what does it cover?
                  <template v-slot:actions>
                    <v-icon
                      color="#005F6C"
                    >
                      mdi-chevron-down
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <br>
                  Travel insurance helps protect you from certain unexpected and potentially costly events that can occur before or during your trip. Ultimately, it’s meant to help you travel with more peace of mind so you can worry less and focus on enjoying your time. <br><br>
                  Insurance coverage for Trip Cancellation can reimburse you for unused, non-refundable, prepaid trip costs if you need to cancel your trip for a covered reason, such as sickness, job loss, flight cancellation due to adverse weather and more. Similarly, insurance coverage for Trip Interruption can recoup your trip costs if you’re unable to continue your trip because of a covered reason—our plans also provide reimbursement for additional transportation costs to help you return home or rejoin your group. <br><br>
                  Travel insurance provides coverage for other unexpected, out-of-pocket costs as well, such as meals and overnight lodging if your travel is delayed*, clothing and personal items if your baggage is delayed* and medical expenses if you get sick or injured during your trip. Plans also provide insurance coverage for your bags and personal effects during your trip and some offerings help protect your sporting equipment while traveling, along with a variety of other available coverages. We recommend comparing our plans and choosing the option that best suits you and your trip. <br><br>
                  *Travel Delay and Baggage Delay coverages can only be claimed if the delay lasts for the amount of time specified in the <a
                    href="https://www.generalitravelinsurance.com/retrieve-policy.html"
                    target="_new"
                  >Schedule of Benefits</a>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header class=" text-h4 font-weight-bold">
                  How does travel insurance work?
                  <template v-slot:actions>
                    <v-icon
                      color="#005F6C"
                    >
                      mdi-chevron-down
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <br>
                  If something happens during your trip and you need to use your travel insurance, you’ll need to <a
                    href="https://www.generalitravelinsurance.com/start-a-claim/how-to.html"
                    target="_new"
                  >file a claim</a> to be reimbursed. We have a page that explains <a
                    href="https://www.generalitravelinsurance.com/start-a-claim/how-to.html"
                    target="_new"
                  >how to start a claim</a>, a great resource for learning more about the claims process. <br><br>
                  Let’s say your baggage is delayed* and you need to buy clothes and toiletries, you would submit a claim to seek reimbursement. Travel insurance comes in handy in scenarios like this. <br><br>
                  Oftentimes, travel insurance is offered when buying a cruise, airfare or booking a vacation rental. We encourage customers to pay attention to the fine print when considering insurance provided by travel suppliers. For example, a <a
                    href="https://www.generalitravelinsurance.com/travel-resources/why-travel-insurance-is-crucial-for-cruise-trips.html"
                    target="_new"
                  >cruise line’s travel insurance</a> may not pay cash to cover a canceled or interrupted cruise, but instead offers credit towards a future cruise. If you prefer cash reimbursement, that’s one reason to consider purchasing directly from a travel insurance provider instead. <br><br>
                  We recommend bringing a print out of your plan documents with you when traveling so if you are affected by a storm and lose power or can’t connect to the internet, you’ll still know what number to call and what instructions to follow. <br><br>
                  <a
                    href="https://www.generalitravelinsurance.com/travel-resources/how-travel-insurance-works.html"
                    target="_new"
                  >Read more about how travel insurance works</a> <br><br>
                  * Baggage Delay coverage can only be claimed if the delay lasts for the amount of time specified in the <a
                    href="https://www.generalitravelinsurance.com/retrieve-policy.html"
                    target="_new"
                  >Schedule of Benefits</a>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header class=" text-h4 font-weight-bold">
                  What are the benefits of travel insurance?
                  <template v-slot:actions>
                    <v-icon
                      color="#005F6C"
                    >
                      mdi-chevron-down
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <br>
                  Our travel insurance product includes a wide range of benefits with a variety of plans and add-ons to help protect your trip. Our plans can help with a number of unexpected problems, including trip cancellations and interruptions; travel delays; lost, damaged and delayed baggage; and unexpected sickness, injury and medical evacuation. Additionally, our 24-Hour Emergency Services, Concierge and ID Theft Resolution services are included with our plans. These services round out our travel protection plans to provide real-time assistance when you need it on your trip. <br><br>
                  In addition to providing added peace of mind, travel insurance can also help international travelers satisfy passport insurance requirements. Depending upon your country of residence and where you’re traveling to, you may be required to possess passport insurance for emergency hospital treatment and emergency medical evacuation before you’re permitted to enter your destination country or receive your visa.
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header class=" text-h4 font-weight-bold">
                  What can I insure?
                  <template v-slot:actions>
                    <v-icon
                      color="#005F6C"
                    >
                      mdi-chevron-down
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <br>
                  You may insure prepaid, non-refundable trip costs.
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header class=" text-h4 font-weight-bold">
                  When is the best time to get travel insurance?
                  <template v-slot:actions>
                    <v-icon
                      color="#005F6C"
                    >
                      mdi-chevron-down
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <br>
                  We suggest you buy a travel insurance plan when you make your first trip payment, like airfare or a cruise. That way you have a long coverage period in case you need to cancel your trip. Later on, you can <a
                    href="https://www.generalitravelinsurance.com/faqs.html#update"
                    target="_new"
                  >add coverage to the same</a> plan for your hotel, tours and other prepaid, non-refundable trip costs. It’s best to contact us to add coverage soon after you make new trip payments to be sure you have coverage for your full trip cost.
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header class=" text-h4 font-weight-bold">
                  When can I buy travel insurance?
                  <template v-slot:actions>
                    <v-icon
                      color="#005F6C"
                    >
                      mdi-chevron-down
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <br>
                  Our travel insurance plans can be purchased up to 18 months before the trip departure date and up until the day prior to departure.
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
        <!---------------------------------COVERAGE AND POLICY-RELATED FAQs------------------------------------>
        <v-row>
          <div
            class="cov-faq-title"
            @click="toggleClaimsFaq"
          >
            <v-col
              cols="12"
              class="faq-header-mobile d-flex align-center"
            >
              Coverage and Policy-Related FAQs
              <v-icon
                v-if="!claimsFaq"
                right
                x-large
                color="#005F6C"
              >
                mdi-chevron-right
              </v-icon>
              <v-icon
                v-if="claimsFaq"
                right
                x-large
                color="#005F6C"
              >
                mdi-chevron-down
              </v-icon>
            </v-col>
          </div>
        </v-row>
        <v-row v-if="claimsFaq">
          <v-col>
            <v-expansion-panels
              focusable
              accordion
            >
              <v-expansion-panel>
                <v-expansion-panel-header class=" text-h4 font-weight-bold">
                  I’ve paid for some of my trip, but not all of it. Can I still buy travel insurance?
                  <template v-slot:actions>
                    <v-icon
                      color="#005F6C"
                    >
                      mdi-chevron-down
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <br>
                  Yes, you can actually buy travel insurance from Blanket before you make any trip payments. <br><br>
                  We recommend the purchase of travel insurance immediately upon the initial payment for your reservation. This provides you with a longer coverage period in which cancellation within the required timeframe can be made if you need to cancel your trip. You always have the option to add coverage to your policy for any additional trip costs, such as hotel reservations, booked tours or other prepaid, non-refundable expenses. To confirm that you are properly covered for all of your trip costs we suggest contacting us for assistance at any point you incur additional costs for your trip.
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header class=" text-h4 font-weight-bold">
                  If my trip is delayed past the original return date, does my travel insurance coverage extend as well?
                  <template v-slot:actions>
                    <v-icon
                      color="#005F6C"
                    >
                      mdi-chevron-down
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <br>
                  Yes, your travel insurance coverage will be extended as long as the entire cost of your trip is included in your plan and the delay is caused by circumstances beyond your control. If coverage is extended, coverage will end either on the date you reach your originally scheduled return destination or seven days after your scheduled return date, whichever comes first.
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header class=" text-h4 font-weight-bold">
                  When does my travel protection plan go into effect and when does it end?
                  <template v-slot:actions>
                    <v-icon
                      color="#005F6C"
                    >
                      mdi-chevron-down
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <br>
                  The Trip Cancellation coverage included with your plan goes into effect the day after you purchase travel protection at 12:01 a.m. On your scheduled departure date, once you begin your trip, you can no longer file a claim for Trip Cancellation. <br><br>
                  As soon as you depart, the other coverages in your plan become effective, including Medical and Dental, Trip Interruption, Travel Delay, Emergency Assistance and Transportation, Baggage and more. Those coverages end as soon as one of these occurs: You return from your trip, the trip is completed, your scheduled return date arrives (unless you qualify for <a
                    href="https://www.generalitravelinsurance.com/faqs.html#extension"
                    target="_new"
                  >extension of coverage</a>), or your arrival at the destination on a one-way trip. <br><br>
                  Once you’re home, you can continue to claim for covered Medical and Dental costs that occur within one year from the date of your Sickness or Injury that occurred during your trip. <br><br>
                  A variety of <a
                    href="https://www.generalitravelinsurance.com/travel-resources/services.html"
                    target="_new"
                  >Travel Services</a> are included with your plan. Concierge Services are available to use as soon as you purchase your plan and end when you return. Travel Support Services can be used during your trip. ID Theft Resolution Services are also available for a full 180 days starting on the scheduled departure date.
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header class=" text-h4 font-weight-bold">
                  Can I be covered for a Pre-Existing Medical Condition?
                  <template v-slot:actions>
                    <v-icon
                      color="#005F6C"
                    >
                      mdi-chevron-down
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <br>
                  If you purchase our Premium plan you can qualify for coverage for pre-existing medical conditions, as long as the travel protection plan is: a) purchased prior to or within 24 hours of your final trip payment; b) you are medically able to travel at the time the plan is purchased; and c) all prepaid trip costs that are subject to cancellation penalties or restrictions have been insured. <br><br>
                  At our <a
                    href="https://www.generalitravelinsurance.com/details/travel-insurance-glossary.html#pre-ex"
                    target="_new"
                  >Travel Insurance Definitions page</a>, you can see how we define Pre-Existing Conditions or see a <a
                    href="https://www.generalitravelinsurance.com/retrieve-policy.html"
                    target="_new"
                  >sample Description of Coverage/Policy</a> for full details.
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header class=" text-h4 font-weight-bold">
                  What are the requirements for coverage due to illness?
                  <template v-slot:actions>
                    <v-icon
                      color="#005F6C"
                    >
                      mdi-chevron-down
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <br>
                  In order to qualify for coverage due to illness, including COVID-19: <br><br>
                  You, a traveling companion or your service animal get sick after you purchase your plan, must seek the in person treatment of a physician and can’t travel according to a physician. Or a non-traveling family member is hospitalized after you purchase your plan and you are unable to make the trip. <br><br>
                  Important Note: Your or your traveling companion’s sickness must first occur after you purchase your plan in order to have coverage. Pre-existing medical conditions are generally excluded from coverage. However, coverage is available with the Premium plan if you purchased your plan prior to or within 24 hours of making Final Payment for your trip, provided other requirements are also met. Review a <a
                    href="https://www.generalitravelinsurance.com/retrieve-policy.html"
                    target="_new"
                  >Description of Coverage/Policy</a> for full details. <br><br>
                  <a
                    href="https://www.generalitravelinsurance.com/details/travel-insurance-glossary.html"
                    target="_new"
                  >Read more for additional details</a>.
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header class=" text-h4 font-weight-bold">
                  How does the Trip Cancellation for Any Reason add-on coverage work?
                  <template v-slot:actions>
                    <v-icon
                      color="#005F6C"
                    >
                      mdi-chevron-down
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <br>
                  Trip Cancellation for Any Reason coverage is available as an optional upgrade to our Premium plan. This coverage reimburses you up to 60% of the penalty amount for your trip when you cancel for any reason. These requirements must be met in order to qualify for Trip Cancellation for Any Reason coverage: <br><br>
                  <ul>
                    <li>The travel insurance plan is purchased within 24 hours of initial deposit for your trip</li>
                    <li>You are not disabled from travel at the time you purchase the plan</li>
                    <li>You have insured 100% of all arrangements that are subject to cancellation penalties or restrictions</li>
                    <li>You cancel your trip 48 hours or more prior to the scheduled departure date</li>
                    <li>The trip cost per person is no more than $10,000</li>
                    <li>All insured travelers cancel the trip</li>
                  </ul> <br>
                  This coverage is not available to residents of New York.
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header class=" text-h4 font-weight-bold">
                  How does rental car damage coverage work?
                  <template v-slot:actions>
                    <v-icon
                      color="#005F6C"
                    >
                      mdi-chevron-down
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <br>
                  Rental Car Damage coverage is included with the Premium plan and is available as an optional add-on coverage with the Standard and Preferred plans. It provides coverage if your rental car is damaged from collision, theft, vandalism, natural disasters or any other cause beyond your control. Coverage is provided for either the cost of repairs and rental charges charged by the rental company while the vehicle is being repaired or the <a
                    href="https://www.generalitravelinsurance.com/details/travel-insurance-glossary.html#cash"
                    target="_new"
                  >Actual Cash Value</a> of the car, whichever is less. <br><br>

                  <span class="font-weight-bold">Note:</span> Trucks, trailers, motorcycles and other vehicles are not eligible for coverage. Review your <a
                    href="https://www.generalitravelinsurance.com/retrieve-policy.html"
                    target="_new"
                  >Description of Coverage/Policy</a> for a full list of excluded vehicles. <br><br>
                  This coverage is not available to residents of Texas.
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header class=" text-h4 font-weight-bold">
                  How does travel insurance work with frequent flyer miles?
                  <template v-slot:actions>
                    <v-icon
                      color="#005F6C"
                    >
                      mdi-chevron-down
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <br>
                  If you paid for air transportation with frequent flyer miles and cancel your trip, you may be reimbursed for the cost to reinstate the frequent flyer miles to your account. This is only available for Trip Cancellation coverage, not Trip Interruption or other coverages. If frequent flyer miles were used to arrange anything other than air transportation, such as lodging accommodations, our plans do not provide coverage for those situations.
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header class=" text-h4 font-weight-bold">
                  Who underwrites Blanket’s travel insurance?
                  <template v-slot:actions>
                    <v-icon
                      color="#005F6C"
                    >
                      mdi-chevron-down
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <br>
                  Blanket's travel insurance is underwritten by Generali Global Assistance. Generali Global Assistance is a full-service travel insurance and assistance provider. Formerly doing business as <a
                    href="https://www.generalitravelinsurance.com/csa-travel-protection.html"
                    target="_new"
                  >CSA Travel Protection</a>, our customers will receive the same quality plans and outstanding customer service they have come to expect from us over the past 25 years, now backed by one of the largest insurance companies in the world. Our plans are built on the diverse needs of today’s travelers, designed to help you travel smarter. <br><br>
                  Generali Global Assistance is part of the multinational Generali Group, which for more than 185 years has built a presence in 60 countries with over 76,000 employees. We pride ourselves on delivering a simpler, smarter approach to insuring and servicing millions of customers each year. <br><br>
                  <a
                    href="https://www.generalitravelinsurance.com/why-generali.html"
                    target="_newet"
                  >Read more about Generali</a>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
        <!---------------------------------INSURANCE TERMS FAQS------------------------------------>
        <v-row>
          <div
            class="cov-faq-title"
            @click="toggleAdditionalCovFaq"
          >
            <v-col
              cols="12"
              class="faq-header-mobile d-flex align-center"
            >
              Insurance Terms FAQs
              <v-icon
                v-if="!additionalCovFaq"
                right
                x-large
                color="#005F6C"
              >
                mdi-chevron-right
              </v-icon>
              <v-icon
                v-if="additionalCovFaq"
                right
                x-large
                color="#005F6C"
              >
                mdi-chevron-down
              </v-icon>
            </v-col>
          </div>
        </v-row>
        <v-row v-if="additionalCovFaq">
          <v-col>
            <v-expansion-panels
              focusable
              accordion
            >
              <v-expansion-panel>
                <v-expansion-panel-header class=" text-h4 font-weight-bold">
                  What does "final payment" mean?
                  <template v-slot:actions>
                    <v-icon
                      color="#005F6C"
                    >
                      mdi-chevron-down
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <br>
                  "Final payment" is the last pre-paid payment (prior to departure) of the most expensive part of the trip, unless the main air arrangements are added last. "Main air arrangements" refer to air to/from the main destination, compared to tickets for a side trip during the vacation.
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header class=" text-h4 font-weight-bold">
                  What is a foreseeable event?
                  <template v-slot:actions>
                    <v-icon
                      color="#005F6C"
                    >
                      mdi-chevron-down
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <br>
                  Foreseeable simply means reasonably known beforehand. Once it is reasonable that people traveling to an area would know about an event, it becomes foreseeable. For example, if employees of the airline you are flying announce that they are going on strike, the event becomes foreseeable once they make the announcement.
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header class=" text-h4 font-weight-bold">
                  What are non-refundable, pre-paid trip costs?
                  <template v-slot:actions>
                    <v-icon
                      color="#005F6C"
                    >
                      mdi-chevron-down
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <br>
                  Non-refundable, pre-paid trip costs are costs associated with your insured trip that you wouldn't recoup if you cancel the trip and are paid before you leave on your trip. Take into account flights, cruises, accommodations, tours, entertainment and other trip costs that could be lost if you're prevented from taking your trip.
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
        <!---------------------------------SPECIFIC-ILLNESS FAQs------------------------------------>
        <v-row>
          <div
            class="cov-faq-title"
            @click="toggleMoreCommonFaq"
          >
            <v-col
              cols="12"
              class="faq-header-mobile d-flex align-center"
            >
              Specific Illness FAQs
              <v-icon
                v-if="!moreCommonFaq"
                right
                large
                color="#005F6C"
              >
                mdi-chevron-right
              </v-icon>
              <v-icon
                v-if="moreCommonFaq"
                right
                x-large
                color="#005F6C"
              >
                mdi-chevron-down
              </v-icon>
            </v-col>
          </div>
        </v-row>
        <v-row v-if="moreCommonFaq">
          <v-col>
            <v-expansion-panels
              focusable
              accordion
            >
              <v-expansion-panel>
                <v-expansion-panel-header class=" text-h4 font-weight-bold">
                  Can Monkeypox be covered by travel insurance?
                  <template v-slot:actions>
                    <v-icon
                      color="#005F6C"
                    >
                      mdi-chevron-down
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <br>
                  Yes, Monkeypox is treated like any other illness and can be covered by Blanket travel insurance. <br><br>
                  Recently, an outbreak of Monkeypox has spread across several countries that don’t normally report monkeypox, including the United States. Monkeypox symptoms include fever, muscle aches, chills, swollen lymph glands and “a rash that can look like pimples or blisters that appears on the face, inside the mouth, and on other parts of the body” according to the Centers for Disease Control. The virus spreads through close contact with someone who has Monkeypox. <br><br>
                  If you, a family member or a traveling companion are diagnosed with Monkeypox before or during your trip, and meet the requirements for coverage due to sickness, you can be covered for Trip Cancellation, Trip Interruption, Travel Delay, Medical & Dental, and Emergency Assistance & Transportation, in addition to our 24/7 Emergency Assistance services. You can also be covered for additional lodging expenses and extension of your travel insurance plan if you are required to isolate at your destination and your return is delayed. <br><br>
                  For your safety and the safety of others, insured travelers who believe they may have contracted the virus should call our <a
                    href="https://www.generalitravelinsurance.com/contact-us.html"
                    target="_new"
                  >24/7 Emergency Assistance team</a> who will coordinate local treatment on your behalf.
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-container>
</template>

<script>
  import HeroComponent from '@/components/base/Hero'

  export default {
    name: 'Dashboard',

    components: {
      HeroComponent
    },

    data () {
      return {
        covFaq: false,
        claimsFaq: false,
        additionalCovFaq: false,
        moreCommonFaq: false,
        tabs: 0,
        list: {
          0: false,
          1: false,
          2: false,
        },
      }
    },
    computed: {
      xlDevice () {
        return this.$vuetify.breakpoint.xlOnly
      },
      mobileDevice () {
        return this.$vuetify.breakpoint.xsOnly
      },
      tabletDevice () {
        return this.$vuetify.breakpoint.smOnly
      },
      largeDevice () {
        return this.$vuetify.breakpoint.lgAndUp
      },
      mediumDevice () {
        return this.$vuetify.breakpoint.mdOnly
      }
    },
    created () {
      this.$gtag.pageview('Travel USA')
      this.currentuser = this.$store.getters.getuser
      if (this.$route.params.partnername) {
        let campaignid = 'unknown'
        let partnername = 'unknown'
        partnername = this.$route.params.partnername
        this.$store.dispatch('setPartnerId', partnername)
        if (this.$route.params.campaignid) {
          campaignid = this.$route.params.campaignid
          this.$store.commit('setCampaignId', campaignid)
        }
        this.$gtag.event('blanketpartner', { method: partnername, campaign: campaignid })
        this.$gtag.pageview('Dashboard' + '-' + partnername)
      }
    },

    methods: {
      toggleCovFaq () {
        this.covFaq = !this.covFaq
      },
      toggleClaimsFaq () {
        this.claimsFaq = !this.claimsFaq
      },
      toggleAdditionalCovFaq () {
        this.additionalCovFaq = !this.additionalCovFaq
      },
      toggleMoreCommonFaq () {
        this.moreCommonFaq = !this.moreCommonFaq
      },
      goTo (route) {
        this.$router.push({ path: route })
      },
      navigateTo (product) {
        this.$router.push(product)
      },
      complete (index) {
        this.list[index] = !this.list[index]
      },
      sendevent () {
        this.$gtag.event('blanket', { method: 'Dashboard', campaign: 'none' })
        return 'completed'
      },
    },
  }
</script>

<style scoped>
.s2-icon {
  font-size: 50px !important;
}

.apply-btn {
  font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 22.6667px;
line-height: 125%;
/* or 28px */

text-align: center;
letter-spacing: 0.944445px;
text-transform: uppercase;

/* Color Palette/Dark Grey */

color: #292F36;
}

.s2-desc {
  font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 150%;
text-align: center;
color: #292F36;
}
  .s2-subhead {
    font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 30px;
line-height: 110%;
text-align: center;
letter-spacing: -0.424019px;
color: #005F6C;
  }
  .s2-sub {
    font-family: 'Poppins';
font-style: normal;
font-weight: 300;
font-size: 32px;
line-height: 120%;
text-align: center;
letter-spacing: -0.424019px;
color: #292F36;
  }

  .cta-txt {
    font-weight: 700;
    font-size: 17.9859px;
    line-height: 125%;
    text-align: center;
    letter-spacing: 0.749412px;
    text-transform: uppercase;
    color: #292F36;
    padding: 0 !important;
  }

  .section2-header {
font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 42px;
line-height: 110%;
text-align: center;
letter-spacing: -0.42px;
color: #292F36;
  }

  .s2-title {
    padding-top: 79px;
  }
.title-text-mobile {
    font-weight: 500;
    color: #616161;
  }
.cover{
  font-size: 55px;
  color: #757575;
}
.read-more{
  font-size: 18px;
  color: #00A1B7;
}
.quote-btn{
  font-weight: bold
}

.section2 {
position: relative;
background-image: url('/img/pattern-white-background.png');
background-position: center;
background-size: cover;
mix-blend-mode: normal;
}
.spacer {
  height: 6rem;
  z-index: 2;
}

.title-text-tablet {
  font-size: 60px;
  font-weight: 500;
  line-height: 132.19%;
  color: #616161;
}
.pet-text {
font-style: normal;
font-weight: normal;
font-size: 60px;
line-height: 70px;
text-align: center;
letter-spacing: -0.005em;
color: #757575;
z-index: 1;
background-color: white;
}
.learn {
  border: 1px solid #00A1B7 !important;
  color: #00A1B7 !important;
}
.btn-mrg1 {
  margin-left: 0;
}
.btn-mrg2 {
  margin-left: 200px;
}
.cov-grp {
  background-color: white;
}
.small {
    height: 700px;
}
.large {
    height: 900px;
}
.zoom {
    transition: transform .2s;
}

.zoom:hover {
    transform: scale(1.2);
}

.section3 {
  position: relative;
  height: 700px;
  background-color: #005F6C;
}

.review-title {
  font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 42px;
line-height: 110%;
letter-spacing: -0.424019px;
color: #FFFFFF;
}

.review-sub {
  font-family: 'Poppins';
font-style: normal;
font-weight: 300;
font-size: 32px;
line-height: 120%;
/* or 38px */

letter-spacing: -0.424019px;

/* white */

color: #FFFFFF;
}

.review-details {
  font-family: 'Poppins';
font-style: italic;
font-weight: 300;
font-size: 21px;
line-height: 140%;
color: #FFFFFF;
}

.review-sig {
  font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 21px;
line-height: 140%;
/* or 29px */

/* white */

color: #FFFFFF;
}

.review-ins {
  font-family: 'Poppins';
font-style: normal;
font-weight: 300;
font-size: 21px;
line-height: 140%;
/* or 29px */

/* white */

color: #FFFFFF;
}

.portrait-card {
position: absolute;
width: 615px;
height: 700px;
left: 880px;
top: -30px;
background: linear-gradient( rgba(0, 0, 0, 0.1), rgba(1, 0, 0, 0) ), url('/img/pet-review-portrait.png');
border-radius: 10px;
}

.portrait-quote {
  width: 300px;
height: 364px;

font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 72px;
line-height: 100%;
/* or 72px */

letter-spacing: -0.636172px;

/* Color Palette/White (100%) */

color: #FFFFFF;
}

.portrait-text-container {
  margin-top:100px;
display: flex;
flex-direction: column;
align-items: flex-start;
padding: 20px 90px 20px 30px;
width: 100%;
height: 251px;
background: #00A1B7;
flex: none;
order: 3;
align-self: stretch;
flex-grow: 0;
z-index: 3;
border-radius: 0 0 0 10px;
}

.portrait-text {
  font-family: 'Poppins';
font-style: italic;
font-weight: 500;
font-size: 17.9695px;
line-height: 125%;
letter-spacing: -0.202611px;
color: #FFFFFF;
}

.signature-container{
  position: absolute;
  right: 10rem;
  top: 42rem;
}

.section4 {
background: #FFFFFF;
padding-left: 10%;
padding-right: 10%;
padding-bottom: 163px;
}

.sec4-title {
  font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 38px;
line-height: 110%;
/* or 42px */

letter-spacing: -0.424019px;

/* Color Palette/Dark Grey */

color: #292F36;
}

.sec4-sub {
font-family: 'Poppins';
font-style: normal;
font-weight: 300;
font-size: 28px;
line-height: 120%;
letter-spacing: -0.424019px;
color: #292F36;
}

.covg-txt {
  font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 150%;
color: #292F36;
}

.covg-cta-txt {
  font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 38px;
line-height: 110%;
/* or 42px */

letter-spacing: -0.424019px;

/* midnight eagle green */

color: #005F6C;
}

.learn-btn-txt {
  font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 125%;
/* identical to box height, or 25px */

display: flex;
align-items: center;
text-align: center;
letter-spacing: 0.00892857em;
text-transform: uppercase;

/* gunmetal */

color: #292F36;
}

.sec5-dsk {
position: relative;
height: 438px;
background-image: url('/img/partner-background.jpg');
background-position: center;
background-size: cover;
padding-left: 10%;
padding-right: 10%;
}

.sec5-dsk-phone {
  position: absolute;
left: 726px;
top: -78px;
}

.sec5-xl-phone {
  position: absolute;
left: 1100px;
top: -78px;
}

.sec5-title {
  position: absolute;
  left: 130px;
  top: 76px;
  font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 42px;
line-height: 110%;
/* or 46px */

letter-spacing: -0.424019px;

/* Color Palette/White (100%) */

color: #FFFFFF;
}

.sec5-sub {
  position: absolute;
  left: 130px;
  top: 131px;
  font-family: 'Poppins';
font-style: normal;
font-weight: 300;
font-size: 32px;
line-height: 125%;
width: 598px;
height: 120px;
/* or 40px */

letter-spacing: -0.424019px;

/* white */

color: #FFFFFF;
}

.apply-btn {
  position: absolute;
  left: 130px;
  top: 289px;
}

.faq-sec-desk {
  background: #FFFFFF;
  padding-left: 10%;
  padding-right: 10%;
}

.faq-header-desk {
  font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 42px;
line-height: 110%;
letter-spacing: -0.424019px;
color: #005F6C;
}

.faq-panel-icon {
  color: #005F6C;
}

.cov-faq-title:hover {
  cursor: pointer;
}

.poppins-300 {
  font-family: 'Poppins';
  font-weight: 300;
}
/*-----------------------------MOBILE STYLES-----------------------------*/
.section1-mobile {
  background-image: url('/img/Mobile-Travel-Hero-Section.jpg');
  background-position: center;
  background-size: cover;
  height: 375px;
}

.section2-mobile {
width: 100vw;
height: 260px;
background: #00A1B7;
}

.section2-title-mobile {
font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 36px;
line-height: 110%;
text-align: center;
letter-spacing: -0.511589px;
color: #FFFFFF;

}

.section2-btn-txt-mobile {
  font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 125%;
display: flex;
align-items: center;
text-align: center;
letter-spacing: 0.00892857em;
text-transform: uppercase;
color: #292F36;
}

.section3-mobile {
  padding-bottom: 54px;
  background-image: url('/img/knit-mobile.png');
  background-position: center;
  background-size: cover;
  padding-left: 10px;
  padding-right: 10px;
}

.section3-title-mobile {
  font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 36px;
line-height: 110%;
text-align: center;
letter-spacing: -0.511589px;
color: #292F36;
}

.section3-sub-mobile {
  font-family: 'Poppins';
font-style: normal;
font-weight: 300;
font-size: 26px;
line-height: 120%;
text-align: center;
letter-spacing: -0.424019px;
color: #292F36;
}

.covg-title-mobile {
font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 30px;
line-height: 110%;
text-align: center;
letter-spacing: -0.424019px;
color: #005F6C;
}

.covg-txt-mobile {
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 150%;
text-align: center;
color: #292F36;
}

.section4-mobile {
  padding-bottom: 54px;
  padding-left: 10px;
  padding-right: 10px;
  background: #FFFFFF;
}

.s4-title-mobile {
font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 36px;
line-height: 110%;
letter-spacing: -0.511589px;
color: #292F36;
}

.s4-sub-mobile {
font-family: 'Poppins';
font-style: normal;
font-weight: 300;
font-size: 26px;
line-height: 120%;
letter-spacing: -0.424019px;
color: #292F36;
}

.covg-txt-mobile {
  font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 150%;
color: #292F36;
text-align: left;
}

.panel-title-mobile {
  font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 150%;
display: flex;
align-items: center;
color: #005F6C;
}

.panel-icon {
  color: #292F36;
  font-size: 20px !important;
}

::v-deep .v-expansion-panel-header--active .v-icon {
  color: #FFFFFF !important;
}

.s5-mobile {
  position: relative;
  min-height: 725px;
  padding-left: 10px;
  padding-right: 10px;
  background-image: url('/img/partner-background-mobile.jpg');
  background-position: center;
  background-size: cover;
}

.s5-title-mobile {
  font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 36px;
line-height: 110%;
text-align: center;
letter-spacing: -0.511589px;
color: #FFFFFF;
}

.s5-sub-mobile {
  font-family: 'Poppins';
font-style: normal;
font-weight: 300;
font-size: 26px;
line-height: 120%;
text-align: center;
letter-spacing: -0.424019px;
color: #FFFFFF;
}

.phone-mobile {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 300px;
  width: auto;
}

.faq-header-mobile {
  font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 20px;
line-height: 110%;
letter-spacing: -0.424019px;
color: #005F6C;
}

.faq-header-mobile {
  padding-right: 20%;
}

/*-----------------------------TABLET STYLES-----------------------------*/
.section2-tablet {
  position: relative;
background-image: url('/img/pattern-white-background.png');
background-position: center;
background-size: cover;
mix-blend-mode: normal;
}

.faq-header-tablet {
  font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 30px;
line-height: 110%;
letter-spacing: -0.424019px;
color: #005F6C;
}

.sora-tablet {
  font-family: 'Sora';
  font-style: normal;
  font-weight: 800;
  font-size: 52px;
  line-height: 110%;
  color: #FFFFFF;
}

  ::v-deep .v-expansion-panel-header {
    color:#00A1B7;
  }

  ::v-deep .v-expansion-panel--active .v-expansion-panel-header {
    background:#00A1B7;
    color:#FFFFFF;
  }

</style>
